<template>
  <!-- 发运信息详情 -->
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <div>{{ $t('title.BasicInformation') }}</div>
    </el-row>
    <el-form label-width="102px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="货主">{{ DetailsData.cargoOwnerCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="逻辑仓名称">{{ DetailsData.logicWarehouseCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="库存期间">{{ DetailsData.inventoryPeriod }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <el-row class="grid-content bg-purple-dark">
      <div>未完结单据信息</div>
    </el-row>
    <el-table ref="multipleTable" class="mb-3" :data="DetailsDataList" :header-cell-style="{background:'#fafafa'}" max-height="500px">
      <el-table-column
        type="index"
        :label="$t('page.No')"
        align="center"
      />
      <el-table-column
        prop="businessCode"
        label="单据号"
        align="center"
      />
      <el-table-column
        prop="businessType"
        label="单据类型"
        align="center"
      />
      <el-table-column
        prop="businessStatus"
        label="状态"
        align="center"
      />

    </el-table>
    <Paging :pager="pager" @update="update" />
    <el-row class="grid-content bg-blue mt-5" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>

</template>

<script>
import { pageUnFinish } from '@/api/inventory-result'
import { Mixin } from '@/mixin/mixin.js'
import Paging from '@/components/Paging'
export default {
  components: {
    Paging
  },
  mixins: [Mixin],
  data() {
    return {
      DetailsData: {},
      DetailsDataList: [],
      pager: {
        size: 20,
        current: 1,
        total: 0
      }
    }
  },

  mounted() {
    const { logicWarehouseCode, cargoOwnerCode, inventoryPeriod } = JSON.parse(this.$route.query.row)
    this.DetailsData = { logicWarehouseCode, cargoOwnerCode, inventoryPeriod }
    this._pageUnFinish()
  },
  methods: {
    cancle() {
      this.$router.go(-1)
    },
    // 获取详情数据
    async _pageUnFinish(params) {
      const { datas: { pager, records }} = await pageUnFinish(this.DetailsData)
      this.pager = pager
      this.DetailsDataList = records
    },
    update(val) {
      this.pager = val
      this._pageUnFinish()
    }

  }
}
</script>
<style lang="scss" scope>
.float-right {
  display: flex;
  justify-content: flex-end;
}
</style>
